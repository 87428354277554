<template>
  <NuxtLink
    v-if="link"
    ref="button"
    :to="link"
    :target="target"
    :class="[
      baseClasses(),
      colorClasses()[disabled ? 'disabled' : color],
      sizeClasses[size],
      loading ? 'cursor-not-allowed opacity-75' : '',
      textSizeClasses[textSize],
    ]"
    class="test"
    :disabled="disabled || loading"
    :data-test="dataTest"
    @click="onClick"
  >
    <DynamicPictogram
      v-if="loading"
      class="py-1"
      name="spinner"
      color="white"
      spin
    />
    <slot v-else />
  </NuxtLink>

  <button
    v-else
    ref="button"
    :type="type"
    :class="[
      baseClasses(),
      colorClasses()[disabled ? 'disabled' : color],
      sizeClasses[size],
      loading ? 'cursor-not-allowed opacity-75' : '',
      textSizeClasses[textSize],
    ]"
    :disabled="disabled || loading"
    :data-test="dataTest"
    @click="onClick"
  >
    <DynamicPictogram
      v-if="loading"
      class="py-1"
      name="spinner"
      color="white"
      spin
    />
    <slot v-else />
  </button>
</template>

<script setup>
const colorClasses = () => ({
  primary: `${props.primaryStyle}`,
  secondary: `${props.secondaryStyle}`,
  dark: `${props.darkStyle}`,
  transparent: `${props.transparentStyle}`,
  transparentOutlined: `${props.transparentOutlinedStyle}`,
  white: `${props.whiteStyle}`,
  link: `${props.linkStyle}`,
  disabled: `${props.disabledStyle}`,
  'outline-supporting-dark-3': `${props.outlineSupportingDark3Style}`,
  'supporting-dark-3-no-border': `${props.supportingDark3NoBorderStyle}`,
});

const sizeClasses = {
  sm: 'h-10',
  base: 'h-11',
};

const textSizeClasses = {
  sm: 'text-sm',
  base: 'text-base',
  md: 'text-md',
  lg: 'text-lg',
  xl: 'text-xl',
};

const baseClasses = () =>
  `flex items-center justify-center transition ease-in-out duration-150 px-2 ${props.baseClasses}`;

const props = defineProps({
  color: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'base',
  },
  textSize: {
    type: String,
    default: 'base',
  },
  link: {
    type: [String],
    default: '',
  },
  value: {
    type: [String, Number],
    required: false,
    default: 0,
  },
  type: {
    type: String,
    default: 'submit',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  focus: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String,
    default: '_self',
  },
  dataTest: {
    type: String,
    default: '',
  },
  baseClasses: {
    type: String,
    default: '',
  },
  primaryStyle: {
    type: String,
    default: 'bg-primary-default hover:bg-primary-hover text-white',
  },
  secondaryStyle: {
    type: String,
    default: 'bg-black/6 text-black disabled:bg-white hover:bg-black/12',
  },
  darkStyle: {
    type: String,
    default: 'bg-gray-700 text-white disabled:bg-gray-700 hover:bg-gray-900',
  },
  transparentStyle: {
    type: String,
    default:
      'bg-transparent text-primary-default disabled:shadow-none hover:text-primary-hover',
  },
  transparentOutlinedStyle: {
    type: String,
    default:
      'bg-transparent text-primary-default disabled:shadow-none hover:shadow-inner border border-solid border-primary-default',
  },
  whiteStyle: {
    type: String,
    default: 'bg-white text-black disabled:shadow-none hover:shadow-inner',
  },
  linkStyle: {
    type: String,
    default: 'bg-transparent text-primary-default font-normal',
  },
  disabledStyle: {
    type: String,
    default: 'bg-black/6 text-black/26 cursor-not-allowed',
  },
  outlineSupportingDark3Style: {
    type: String,
    default:
      'bg-transparent text-supporting-dark-3 border border-supporting-dark-3',
  },
  supportingDark3NoBorderStyle: {
    type: String,
    default: 'bg-transparent text-supporting-dark-3',
  },
});

const emit = defineEmits(['click']);

const onClick = (event) => {
  // Blur element to avoid any lingering focus outline after click
  event.target.blur();

  if (!props.disabled && !props.loading) {
    emit('click', event);
  }
};
</script>
